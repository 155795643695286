<template>
  <UiPreloader v-if="!user" avatar />
  <div v-else class="relative">
    <button
      ref="triggerRef"
      class="flex items-center"
      @click="toggleDropdown"
      @keydown.space.exact.prevent="showDropdown"
      @keydown.enter.exact.prevent="showDropdown"
      @keydown.esc.exact="hideDropdown"
      @keydown.shift.tab="hideDropdown"
    >
      <UiAvatar :initials="avatarInitials" />
      <DownIcon class="ml-2 size-4" />
    </button>
    <div class="absolute w-full">&nbsp;</div>
    <transition name="dropdown-fade">
      <ul
        v-if="isVisible"
        ref="dropdownRef"
        class="absolute right-0 z-20 mt-2 w-40 overflow-hidden rounded-lg border border-grey bg-white shadow-custom dark:border-grey/10 dark:bg-dark-secondary"
      >
        <li class="group">
          <a
            href=""
            class="dropdown-item"
            @keydown.esc.exact="hideDropdown"
            @keydown.down.exact.prevent="focusNext(true)"
            @keydown.tab.exact="focusNext(false)"
            @keydown.up.exact.prevent=""
            @click.prevent="navigateToPath"
          >
            <UserIcon class="h-5 w-5" />
            <span class="ml-2">Profile</span>
          </a>
        </li>
        <li class="group">
          <a
            href=""
            class="dropdown-item"
            @click.prevent="logout"
            @keydown.esc.exact="hideDropdown"
            @keydown.tab.exact="hideDropdown"
            @keydown.up.exact.prevent="focusPrevious(true)"
            @keydown.shift.tab="focusPrevious(false)"
            @keydown.down.exact.prevent=""
          >
            <LogoutIcon class="h-5 w-5" />
            <span class="ml-2">Logout</span>
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import DownIcon from '@/assets/icons/chevron-down.svg?component'
import UserIcon from '@/assets/icons/user.svg?component'
import LogoutIcon from '@/assets/icons/logout.svg?component'

// Reusable composables
const { socket } = storeToRefs(useSocketStore())
const { user } = storeToRefs(useUserStore())
const { logoutUser } = useUser()

const isVisible = ref(false)
const focusedIndex = ref(0)
const triggerRef = ref<HTMLButtonElement>()
const dropdownRef = ref<HTMLUListElement>()

onClickOutside(triggerRef, () => {
  hideDropdown()
})

const avatarInitials = computed(() => {
  if (user.value) {
    return user.value.firstName + ' ' + user.value.lastName
  }
  return ''
})

function toggleDropdown() {
  isVisible.value = !isVisible.value
}
function showDropdown() {
  isVisible.value = true
}
function hideDropdown() {
  isVisible.value = false
}
function focusPrevious(isArrowKey: boolean) {
  focusedIndex.value = focusedIndex.value - 1
  if (isArrowKey) {
    focusItem()
  }
}
function focusNext(isArrowKey: boolean) {
  focusedIndex.value = focusedIndex.value + 1
  if (isArrowKey) {
    focusItem()
  }
}
function focusItem() {
  // dropdown.value?.children[focusedIndex.value].children[0].focus()
  const listItem = dropdownRef.value?.children[
    focusedIndex.value
  ] as HTMLLIElement
  const anchorItem = listItem.children[0] as HTMLAnchorElement
  anchorItem.focus()
}
function navigateToPath() {
  hideDropdown()
  return navigateTo('/profile')
}
function logout() {
  hideDropdown()
  if (socket.value.connected) {
    socket.value.disconnect()
  }
  logoutUser()
}
</script>

<style scoped>
.dropdown-item {
  @apply flex cursor-pointer items-center px-5 py-3 group-hover:text-primary;
}
</style>
