<template>
  <div>
    <SideNav />
    <SideNavDrawer :show="toggleDrawer" @close="toggleDrawer = false" />
    <div class="dashboard relative lg:ml-60">
      <NavHeader @toggle="toggleDrawer = true" />
      <slot />
    </div>
    <LazyBottomNav v-if="!excludeBottomNavForRoute" />
    <WalletFundModal ref="fundModal" @update="paymentUpdate($event)" />
    <LazyProvidusTransferModal
      v-if="showTransferModal"
      ref="providusTransferModal"
      :params="transferParams"
    />
  </div>
</template>

<script setup lang="ts">
import type { ProvidusTransferParams } from '@/types/payment'
import WalletFundModal from '~/components/modals/pages/wallet/WalletFundModal.vue'
import type ProvidusTransferModal from '~/components/modals/pages/wallet/ProvidusTransferModal.vue'

// Reusable composables
const route = useRoute()
const { fetchUserInfo } = useUser()
const { fetchNotifications } = useNotification()
const token = useCookie('lk_token')
const { user } = storeToRefs(useUserStore())
const socketStore = useSocketStore()
const { socket } = storeToRefs(useSocketStore())
const { fetchTransactions } = useTransaction()
const { fetchActivities } = useActivity()
const { hasNotification } = storeToRefs(useNotificationStore())
const { showFundModal } = storeToRefs(useModalStore())
const { fetchPaymentOptions } = usePayment()

const toggleDrawer = ref(false)

if (token.value) {
  socketStore.setSocket(token.value)
}

initSetup()

const excludeBottomNavForRoute = computed(() => {
  return !!(
    route.path === '/chatroom' ||
    route.path === '/games/pacesetter' ||
    route.path === '/games/numbers/power-number' ||
    route.name === 'games-quickdraw-drawId-subDrawId'
  )
})

watch(user, async (val) => {
  if (val?.id) {
    await nextTick(() => {
      fetchTransactions()
      fetchActivities()
    })
  }
})

function initSetup() {
  fetchUserInfo()
  if (!socket.value?.connected) {
    socket.value.connect()
  }
  listenForNotifications()
  fetchPaymentOptions()
}
function listenForNotifications() {
  socket.value.on('new notification', () => {
    fetchNotifications()
    hasNotification.value = true
  })
}

// Funding
const fundModal = ref<InstanceType<typeof WalletFundModal> | null>(null)
const providusTransferModal = ref<InstanceType<
  typeof ProvidusTransferModal
> | null>(null)
const transferParams = ref<ProvidusTransferParams | null>(null)
const showTransferModal = ref(false)

watchEffect(() => {
  if (showFundModal.value) {
    fundModal.value?.open()
  }
})
watchEffect(() => {
  if (providusTransferModal.value !== null) {
    providusTransferModal.value?.open()
  }
})
async function paymentUpdate(params: ProvidusTransferParams) {
  transferParams.value = params
  fundModal.value?.closeModal()
  await sleep(500)
  showTransferModal.value = true
  providusTransferModal.value?.open()
}
</script>
