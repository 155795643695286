<template>
  <aside>
    <div
      v-if="show"
      class="fixed right-0 top-0 z-50 h-full w-full cursor-pointer bg-black/70 backdrop-blur-sm"
      @click="$emit('close')"
    />
    <transition name="slide-nav" mode="out-in">
      <nav
        v-if="show"
        class="fixed left-0 top-0 z-50 flex h-full w-64 flex-col justify-between bg-lemon-dark-green"
      >
        <div
          class="mt-5 flex flex-col overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-grey dark:scrollbar-thumb-grey/10"
        >
          <div class="px-5">
            <AppLogo no-text />
          </div>
          <MenuItems class="mt-5" @close="$emit('close')" />
        </div>
      </nav>
    </transition>
  </aside>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    show: boolean
  }>(),
  {
    show: false,
  },
)

defineEmits(['close'])

const { show } = toRefs(props)

onUnmounted(() => {
  if (show.value) {
    document.body.style.removeProperty('overflow')
  }
})

watch(show, (value) => {
  if (value) {
    document.body.style.setProperty('overflow', 'hidden')
  } else {
    document.body.style.removeProperty('overflow')
  }
})
</script>
