<template>
  <ul class="space-y-2">
    <li
      v-for="(menu, index) in menuItems"
      :key="index"
      class="group"
      @click="$emit('close')"
    >
      <nuxt-link
        :to="menu.url"
        class="mx-3 flex items-center gap-3 px-5 py-3 text-sm text-white transition duration-500 group-hover:text-primary"
      >
        <component
          :is="menu.icon"
          class="size-5 transition duration-500 group-hover:text-primary"
        />
        {{ menu.name }}
      </nuxt-link>
    </li>
  </ul>
</template>

<script setup lang="ts">
import HomeIcon from '@/assets/icons/home.svg?component'
import SwatchIcon from '@/assets/icons/swatch.svg?component'
import ActivityIcon from '@/assets/icons/chart_bar.svg?component'
import DocumentIcon from '@/assets/icons/document.svg?component'
import WalletIcon from '@/assets/icons/wallet.svg?component'
import CardIcon from '@/assets/icons/card.svg?component'
import QuestionIcon from '@/assets/icons/question.svg?component'
import ChatIcon from '@/assets/icons/chat.svg?component'

defineEmits(['close'])

const menuItems = [
  { name: 'Home', url: '/home', icon: markRaw(HomeIcon) },
  { name: 'Games', url: '/games', icon: markRaw(SwatchIcon) },
  {
    name: 'Activities',
    url: '/activities',
    icon: markRaw(ActivityIcon),
  },
  {
    name: 'Transactions',
    url: '/transactions',
    icon: markRaw(DocumentIcon),
  },
  {
    name: 'Wallet',
    url: '/wallet',
    icon: markRaw(WalletIcon),
  },
  {
    name: 'Payment',
    url: '/payment',
    icon: markRaw(CardIcon),
  },
  {
    name: 'Complaint',
    url: '/complaint',
    icon: markRaw(QuestionIcon),
  },
  {
    name: 'Community',
    url: '/chatroom',
    icon: markRaw(ChatIcon),
  },
]
</script>
