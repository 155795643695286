<template>
  <header class="sticky top-0 z-40 w-full">
    <NetworkStatus />
    <nav
      :class="[extendNavbar || 'h-16']"
      class="w-full border-b border-grey/60 bg-background/20 shadow-custom backdrop-blur-sm dark:border-grey/5 dark:bg-dark-primary/20"
    >
      <div class="container">
        <div class="flex h-16 w-full items-center justify-between gap-2">
          <button class="md:flex lg:hidden" @click="handleNavMenuClick">
            <BackIcon v-if="isBackButtonVisible" class="size-5" />
            <MenuIcon v-else class="size-5" />
          </button>
          <div
            class="w-full text-center text-xs font-bold uppercase tracking-wider"
          >
            {{ pageTitle }}
          </div>
          <div class="flex items-center">
            <NotificationDropdown class="mr-5" />
            <NavDropdownMenu />
          </div>
        </div>
        <LazyChatNavHeader v-if="route.name === 'chatroom'" />
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import MenuIcon from '@/assets/icons/menu.svg?component'
import BackIcon from '@/assets/icons/arrow-left.svg?component'

const emit = defineEmits(['toggle'])

// Reusable composable
const route = useRoute()
const router = useRouter()
const { pageTitle } = storeToRefs(useAppStore())

const extendNavbar = computed(() => {
  return route.name === 'chatroom'
})
const isBackButtonVisible = computed(() => {
  if (route.path === '/home') {
    return false
  } else {
    return true
  }
})

function handleNavMenuClick() {
  if (router.getRoutes().length === 0) {
    return navigateTo('/home', { replace: true })
  } else if (!isBackButtonVisible.value) {
    emit('toggle')
  } else if (route.name === 'games-quickdraw-drawId-subDrawId') {
    return navigateTo('/home', { replace: true })
  } else if (route.path === '/chatroom') {
    return navigateTo('/home', { replace: true })
  } else {
    router.back()
  }
}
</script>
