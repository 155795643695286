<template>
  <aside
    class="relative hidden h-svh bg-lemon-dark-green lg:fixed lg:bottom-0 lg:left-0 lg:top-0 lg:flex lg:flex-none lg:flex-col lg:justify-between"
  >
    <nav
      class="mt-4 flex h-full w-60 flex-col overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-grey dark:scrollbar-thumb-grey/10"
    >
      <div class="px-5">
        <AppLogo no-text />
      </div>
      <MenuItems class="mt-5" />
    </nav>
  </aside>
</template>
