<template>
  <UiModal ref="modal" :is-blocking="true" @close="closeModal">
    <div>
      <h2 class="text-lg font-bold">Top Up</h2>
      <AvailableBalance class="mt-5" />
      <p class="mt-2 text-center text-xs font-bold text-primary">
        Minimum fund amount: ₦{{ MINIMUM_AMOUNT }}
      </p>
      <p
        class="mt-3 flex items-center justify-center gap-2 rounded bg-blue-200/5 p-2 text-center text-xs font-bold uppercase text-blue-500 shadow-custom"
      >
        Note: You don't need to have a Bank account with the payment providers
        Below to fund
      </p>

      <form class="mt-5" @submit.prevent="onSubmit">
        <input
          ref="amountInput"
          v-model="amount"
          name="amount"
          type="text"
          inputmode="numeric"
          placeholder="0"
          class="form-input-amount"
          @keypress="isNumber"
        />
        <FormError
          v-show="errors.amount"
          :message="errors.amount"
          class="justify-center"
        />
        <div v-if="isFetching.paymentOption" class="mt-3 space-y-3">
          <UiSkeleton class="h-7 w-[80%]" />
          <UiSkeleton class="h-6 w-[50%]" />
        </div>
        <UiErrorState
          v-else-if="!isFetching.paymentOption && isFetching.paymentOptionError"
          message="Our payment providers is down, please try again later"
          @try-again="fetchPaymentOptions"
        />
        <div v-else class="mt-5 space-y-2">
          <SelectPaymentOption
            v-for="option in paymentOptions"
            :key="option._id"
            v-model="paymentOption"
            :option="option"
          />
        </div>
        <FormError v-show="errorMessage" :message="errorMessage" class="mt-3" />
        <UiButton
          type="submit"
          rounded
          raised
          class="ml-auto mt-8"
          :loading="loading"
          :disabled="disabled"
        >
          {{ loading ? '' : 'Proceed' }}
        </UiButton>
      </form>
    </div>
  </UiModal>
</template>

<script setup lang="ts">
import { useField, useForm } from 'vee-validate'
import * as Yup from 'yup'
import UiModal from '~/components/ui/UiModal.vue'
import type { PaymentOption, ProvidusTransferParams } from '@/types/payment'

// import PaystackPop from '@paystack/inline-js'

const emit = defineEmits<{
  update: [value: ProvidusTransferParams]
}>()

// Reusable composables
const { api } = useApi()
const { logError } = useSentry()
const modalStore = useModalStore()
const { loading: isFetching, fetchPaymentOptions } = usePayment()
const { providusTransferPayload, paymentOptions } =
  storeToRefs(usePaymentStore())
// const { user } = storeToRefs(useUserStore())
// const app = useAppStore()
// const { PAYSTACK_KEY } = useRuntimeConfig().public

const schema = Yup.object({
  amount: Yup.string().required().label('Amount'),
})
const { errors, handleSubmit, resetForm, setFieldError } = useForm({
  validationSchema: schema,
  initialValues: {
    amount: '',
  },
})
const { value: amount } = useField<string>('amount')
const amountInput = ref<HTMLInputElement | null>(null)
const modal = ref<InstanceType<typeof UiModal> | null>(null)
const errorMessage = ref('')
const loading = ref(false)
const paymentOption = ref<PaymentOption | null>(null)
const MINIMUM_AMOUNT = 100

watchEffect(() => {
  if (amountInput.value !== null) {
    amountInput.value?.focus()
  }
})
watch(amount, async (val) => {
  if (val) {
    amount.value = parseCurrency(val).toLocaleString()
    await sleep(10)
    validateAmount(val)
  }
})
function validateAmount(amount: string) {
  if (parseCurrency(amount) < MINIMUM_AMOUNT) {
    errors.value.amount = `Minimum amount to fund is ₦${formatNumberWithComma(MINIMUM_AMOUNT)}`
    setFieldError(
      'amount',
      `Minimum amount to fund is ₦${formatNumberWithComma(MINIMUM_AMOUNT)}`,
    )
  }
}

const disabled = computed(() => {
  if (loading.value) {
    return true
  } else {
    return !!(
      amount.value === '' ||
      modifiedAmount.value < MINIMUM_AMOUNT ||
      errors.value.amount ||
      paymentOption.value === null
    )
  }
})
const modifiedAmount = computed(() => {
  return parseCurrency(amount.value)
})

const onSubmit = handleSubmit(async () => {
  if (paymentOption.value?.name.toLowerCase() === 'palmpay') {
    fundWithPalmPay()
  } else if (paymentOption.value?.name.toLowerCase() === 'opay') {
    fundWithOPay()
  } else if (paymentOption.value?.name.toLowerCase() === 'providus') {
    fundWithProvidus()
  }
  // const paystack = new PaystackPop()
  /* paystack.newTransaction({
    key: PAYSTACK_KEY,
    email: user.value?.email,
    amount: modifiedAmount.value,
    currency: 'NGN',
    channels: ['card', 'bank', 'ussd', 'bank_transfer'],
    firstName: user.value?.firstName,
    lastName: user.value?.lastName,
    metadata: {
      _id: user.value?.id,
    },
    onSuccess: (transaction: any) => {
      verifyTransaction(transaction)
    },
    onCancel: () => {
      dismissPayment()
    },
  }) */
})
/* async function verifyTransaction(transaction: any) {
  try {
    loading.value = true
    const response = await api.wallet.verifyTransaction(transaction.reference)
    if (response.status) {
      await sleep(1500)
      app.showAlertModal({
        status: 'success',
        title: 'Wallet funded successfully',
      })
      dismissPayment()
    }
    loading.value = false
  } catch (err: any) {
    app.showAlertModal({
      status: 'error',
      title: formatErrorResponse(err),
    })
    loading.value = false
  }
} */

async function fundWithPalmPay() {
  try {
    loading.value = true
    errorMessage.value = ''
    const response = await api.payment.fundWithPalmPay(modifiedAmount.value)
    if (response) {
      window.open(response.data.checkoutUrl, '_self')
    }
  } catch (err) {
    loading.value = false
    errorMessage.value = formatErrorResponse(err)
    logError(err)
  }
}
async function fundWithOPay() {
  try {
    loading.value = true
    errorMessage.value = ''
    const response = await api.payment.fundWithOPay(modifiedAmount.value)
    if (response) {
      window.open(response.data.cashierUrl, '_self')
    }
  } catch (err) {
    loading.value = false
    errorMessage.value = formatErrorResponse(err)
    logError(err)
  }
}
async function fundWithProvidus() {
  if (providusTransferPayload.value) {
    emit('update', {
      accountName: providusTransferPayload.value.accountName,
      accountNumber: providusTransferPayload.value.accountNumber,
      amount: amount.value,
    })
  } else {
    try {
      loading.value = true
      errorMessage.value = ''
      const response = await api.payment.fundWithProvidus()
      loading.value = false
      if (response) {
        providusTransferPayload.value = {
          ...response.data,
          amount: amount.value,
        }
        emit('update', {
          accountName: response.data.accountName,
          accountNumber: response.data.accountNumber,
          amount: amount.value,
        })
      }
    } catch (err) {
      loading.value = false
      errorMessage.value = formatErrorResponse(err)
      logError(err)
    }
  }
}

function open() {
  modal.value?.open()
}
function closeModal() {
  resetForm({
    values: {
      amount: '',
    },
  })
  paymentOption.value = null
  modalStore.openFundModal(false)
  modal.value?.close()
}

defineExpose({
  open,
  closeModal,
})
</script>
