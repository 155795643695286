<template>
  <div ref="notificationDropdown" class="relative">
    <button class="relative z-20" @click="toggleDropdown">
      <BellIcon class="size-6" />
      <NotificationDot v-show="hasNotification" />
    </button>
    <div class="absolute w-full">&nbsp;</div>
    <transition name="dropdown-fade">
      <div
        v-if="isVisible"
        :class="[
          notifications?.length === 0
            ? 'overflow-y-hidden'
            : 'overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-grey dark:scrollbar-thumb-grey/10',
        ]"
        class="absolute right-0 z-20 h-[550px] w-96 overflow-hidden rounded-lg border border-grey bg-white shadow-2xl dark:border-grey/10 dark:bg-dark-primary"
      >
        <h2 class="p-5 text-lg font-bold">Notifications</h2>
        <NotificationList class="px-5" @close="hideDropdown" />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import BellIcon from '@/assets/icons/bell.svg?component'

// Reusable composables
const { hasNotification, notifications } = storeToRefs(useNotificationStore())
const { updateNotificationStatus } = useNotification()
const { $isMedScreenUp } = useNuxtApp()

const isVisible = ref(false)
const notificationDropdown = ref<HTMLDivElement>()

onClickOutside(notificationDropdown, () => hideDropdown())

function toggleDropdown() {
  if ($isMedScreenUp.value) {
    isVisible.value = !isVisible.value
  } else {
    return navigateTo('/notifications')
  }

  updateNotification()
}
function hideDropdown() {
  isVisible.value = false
}
function updateNotification() {
  if (hasNotification.value) {
    updateNotificationStatus()
  }
}
</script>
