<template>
  <button
    :class="[
      modelValue?._id === option?._id
        ? 'border-primary bg-primary-dark/5'
        : 'border-grey dark:border-grey/10',
      !option.value ? 'blur-sm' : '',
    ]"
    class="flex w-full cursor-pointer items-center justify-center gap-1 rounded border p-3 text-sm shadow-custom transition-colors duration-300 ease-in-out hover:border-primary hover:bg-primary-dark/10 disabled:cursor-not-allowed disabled:hover:bg-transparent"
    :disabled="!option.value"
    @click.prevent="updateSelection"
  >
    <img
      :src="getPaymentOptionImage(option.name)"
      :alt="option.name"
      class="size-6 object-cover"
    />
    <p class="text-light-text dark:text-dark-light-text">
      {{ getPaymentOptionTitle(option.name) }}
    </p>
  </button>
</template>

<script setup lang="ts">
import type { PaymentOption } from '@/types/payment'

const props = defineProps<{
  option: PaymentOption
}>()

const modelValue = defineModel<PaymentOption | null>()

function getPaymentOptionImage(name: string) {
  if (name.toLowerCase() === 'palmpay') {
    return '/assets/images/palm-pay-logo.png'
  } else if (name.toLowerCase() === 'opay') {
    return '/assets/images/opay-logo.png'
  } else if (name.toLowerCase() === 'providus') {
    return '/assets/images/providus-logo.png'
  }
}
function getPaymentOptionTitle(name: string) {
  if (name.toLowerCase() === 'palmpay') {
    return 'Pay With PalmPay'
  } else if (name.toLowerCase() === 'opay') {
    return 'Pay With Opay'
  } else if (name.toLowerCase() === 'providus') {
    return 'Pay With Providus Bank'
  }
}
function updateSelection() {
  modelValue.value = props.option
}
</script>
